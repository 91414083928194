import React from 'react'
import { IonCol, IonGrid, IonHeader, IonRow, IonSearchbar, IonToolbar } from '@ionic/react'
import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  header: {
    height: '120px',
    '@media (max-width: 768px)': {
      height: '80px',
    },
  },
  toolbar: {
    height: '100%',
    padding: '0 15px',
  },
  grid: {
    height: '100%',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  logoImage: {
    height: '110px',
    '@media (max-width: 768px)': {
      height: '60px',
    },
  },
  title: {
    fontSize: '48px',
    fontWeight: 'bold',
    fontFamily: "'Montserrat', sans-serif",
    color: '#333',
    textAlign: 'center',
    flex: 1,
    '@media (max-width: 768px)': {
      fontSize: '24px',
    },
  },
  searchbar: {
    '--border-radius': '50px',
    '--background': '#fff',
    '--box-shadow': '0 2px 4px rgba(0, 0, 0, 0.1)',
    width: '100%',
  },
  searchbarContainer: {
    marginRight: '30px',
  },
})

function Header() {
  const classes = useStyles()

  return (
    <IonHeader className={classes.header}>
      <IonToolbar className={classes.toolbar}>
        <IonGrid className={classes.grid}>
          <IonRow className={classes.row}>
            <IonCol size="auto">
              <Link to="/" className={classes.logoLink}>
                <img
                  src="https://storage.googleapis.com/ortho_pictures/logo_small.png"
                  alt="Orthoqle"
                  className={classes.logoImage}
                />
              </Link>
            </IonCol>
            <IonCol size="auto">
            </IonCol>
            <IonCol size="auto" className={classes.searchbarContainer}>
              {/*<IonSearchbar*/}
              {/*  placeholder="Rechercher"*/}
              {/*  className={classes.searchbar}*/}
              {/*></IonSearchbar>*/}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonHeader>
  )
}

export default Header
