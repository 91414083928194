import React, { ReactElement } from 'react'
import { IonCard } from '@ionic/react'
import { createUseStyles } from 'react-jss'
import { GameFrameProps } from '../interfaces/game-props.interface'

const useStyles = createUseStyles({
  card: {
    padding: '40px',
    width: '60%',
    borderRadius: '20px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    transition: 'transform 0.3s ease-in-out',
    border: '3px solid #f0f0f0',
    position: 'relative',
    overflow: 'hidden',
    margin: "auto"
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '600px',
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    zIndex: 2, // Ensure content is above the background image
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.05, // Adjust opacity as needed
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }
})

function GameFrame({ children, backgroundUrl }: GameFrameProps): ReactElement {
  const classes = useStyles()

  return (
    <IonCard className={classes.card}>
      <div
        className={classes.backgroundImage}
        style={{ backgroundImage: `url(${backgroundUrl})` }}
      ></div>
      <div className={classes.content}>
        {children}
      </div>
    </IonCard>
  )
}

export default GameFrame
