import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './ortho/HomePage'
import Anagram from "./games/anagram/Anagram"
import '@ionic/react/css/core.css'

import { setupIonicReact } from '@ionic/react'
import Memory from "./games/memory/Memory"
setupIonicReact()

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/anagrammes" element={<Anagram />} />
        <Route path="/memory" element={<Memory />} />
      </Routes>
    </Router>
  )
}

export default App
