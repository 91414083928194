import React, { ReactElement, useState, useEffect } from 'react'
import {
  IonGrid, IonRow, IonCol, IonInput, IonText, IonTitle, IonButton, IonIcon, IonPage
} from '@ionic/react'
import { checkmarkOutline, refreshOutline, closeOutline } from 'ionicons/icons'
import GameFrame from '../../components/GameFrame'
import { createUseStyles } from 'react-jss'
import Papa from 'papaparse'
import Header from "../../components/Header"

const useStyles = createUseStyles({
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '28px',
    color: '#333',
  },
  grid: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  shuffledWord: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    fontSize: '24px',
    letterSpacing: '15px',
    color: '#555',
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', // Align items vertically center
    marginBottom: '20px',
    position: 'relative', // To position the validation icon at the end
  },
  inputBox: {
    width: '50px',
    height: '50px',
    margin: '0 5px',
    textAlign: 'center',
    fontSize: '24px',
    border: '2px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  correctInput: {
    borderColor: '#28a745',
    backgroundColor: '#d4edda',
  },
  incorrectInput: {
    borderColor: '#dc3545',
    backgroundColor: '#f8d7da',
  },
  validationIcon: {
    position: 'absolute',
    right: '-35px', // Adjust as needed
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '24px',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  button: {
    margin: '0 10px',
    fontSize: '18px',
    padding: '10px 20px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  buttonIcon: {
    marginRight: '5px',
  },
})

function shuffleWord(word: string): string {
  return word.toUpperCase().split('').sort(() => Math.random() - 0.5).join('')
}

function removeAccents(word: string): string {
  return word.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

function AnagramGame(): ReactElement {
  const classes = useStyles()
  const [shuffledWord, setShuffledWord] = useState<string>('')
  const [rightWord, setRightWord] = useState<string>('')
  const [userInput, setUserInput] = useState<string[]>([])
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null)
  const [attempts, setAttempts] = useState<number>(0)
  const [hints, setHints] = useState<boolean[]>([])

  const handleStartGame = () => {
    fetch('/assets/games/anagrams/words.csv')
      .then(response => response.text())
      .then(data => {
        Papa.parse(data, {
          header: false,
          dynamicTyping: true,
          skipEmptyLines: true,
          complete: (results) => {
            const words = results.data
              .map((row: any) => removeAccents(row[2]))
              .filter((word: string) => word.length >= 5 && word.length <= 9)
            const randomWord = words[Math.floor(Math.random() * words.length)]
            setShuffledWord(shuffleWord(randomWord))
            setRightWord(randomWord)
            setUserInput(Array(randomWord.length).fill(''))
            setIsCorrect(null)
            setAttempts(0)
            setHints(Array(randomWord.length).fill(false))
          },
        })
      })
  }

  useEffect(() => {
    handleStartGame() // Start game initially with a random word
  }, [])

  const handleChange = (event: any, index: number) => {
    const value = event.target.value
    if (value.length > 1) return // Prevent multiple characters

    const newInput = [...userInput]
    newInput[index] = value.toUpperCase()
    setUserInput(newInput)

    // Focus on the next input
    if (value && index < userInput.length - 1) {
      const nextInput =
          document.querySelector(`ion-input[data-index="${index + 1}"] input`) as HTMLInputElement
      if (nextInput) {
        if (userInput[index + 1] !== '') {
          setUserInput((prev) => {
            const updatedInput = [...prev]
            updatedInput[index + 1] = ''
            return updatedInput
          })
        }
        setTimeout(() => nextInput.focus(), 0) // Ensure focus happens after state update
      }
    }
  }

  const handleFocus = (index: number) => {
    setUserInput((prev) => {
      const newInput = [...prev]
      if (newInput[index] !== '') {
        newInput[index] = ''
      }
      return newInput
    })
  }

  const handleKeyDown = (event: React.KeyboardEvent, index: number) => {
    setIsCorrect(null)
    if (event.key === 'Enter') {
      handleCheckAnswer()
    } else if (event.key === 'Backspace') {
      if (userInput[index] === '') {
        if (index > 0) {
          const prevInput =
              document.querySelector(`ion-input[data-index="${index - 1}"] input`) as HTMLInputElement
          if (prevInput) {
            setTimeout(() => prevInput.focus(), 0) // Ensure focus happens after state update
          }
        }
      } else {
        setUserInput((prev) => {
          const newInput = [...prev]
          newInput[index] = ''
          return newInput
        })
      }
    }
  }

  const handleCheckAnswer = () => {
    const answerArray = userInput.map((char, index) =>
      hints[index] ? rightWord[index].toUpperCase() : char
    )
    const answer = answerArray.join('')
    if (answer.toLowerCase() === rightWord.toLowerCase()) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
      setAttempts((prev) => {
        const newAttempts = prev + 1
        if (newAttempts % 3 === 0) {
          setHints((prevHints) => {
            const newHints = [...prevHints]
            const hintIndex = Math.floor(newAttempts / 3) - 1
            if (hintIndex < rightWord.length) {
              newHints[hintIndex] = true
              setUserInput((prev) => {
                const updatedInput = [...prev]
                updatedInput[hintIndex] = rightWord[hintIndex].toUpperCase()
                return updatedInput
              })
            }
            return newHints
          })
        }
        return newAttempts
      })
    }
  }

  return (
    <IonPage>
      <Header />
      <GameFrame backgroundUrl={'https://storage.googleapis.com/ortho_pictures/anagrams.png'}>
        <IonRow className={classes.row}>
          <IonCol>
            <IonTitle className={classes.title}>Anagrammes</IonTitle>
          </IonCol>
        </IonRow>
        <IonGrid className={classes.grid}>
          <IonRow className={classes.row}>
            <IonCol>
              <IonText className={classes.shuffledWord}><b>{shuffledWord}</b></IonText>
            </IonCol>
          </IonRow>
          <IonRow className={classes.inputRow}>
            {userInput.map((char, index) => (
              <IonInput
                key={index}
                value={char}
                inputMode="text"
                onIonInput={(e) => handleChange(e, index)}
                onIonFocus={() => handleFocus(index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                className={`${classes.inputBox} 
                ${isCorrect !== null && (isCorrect ? classes.correctInput : classes.incorrectInput)}`}
                data-index={index}
                maxlength={1}
                readonly={hints[index]} // Make input readonly if it's a hint
              />
            ))}
            {isCorrect !== null && (
              <IonIcon
                icon={isCorrect ? checkmarkOutline : closeOutline}
                className={classes.validationIcon}
                color={isCorrect ? "success" : "danger"}
              />
            )}
          </IonRow>
          <IonRow className={classes.buttonRow}>
            <IonButton className={classes.button} onClick={handleCheckAnswer}>
              <IonIcon icon={checkmarkOutline} className={classes.buttonIcon} />
                Vérifier
            </IonButton>
            <IonButton className={classes.button} onClick={handleStartGame} color="secondary">
              <IonIcon icon={refreshOutline} className={classes.buttonIcon} />
                Recommencer
            </IonButton>
          </IonRow>
        </IonGrid>
      </GameFrame>
    </IonPage>
  )
}

export default AnagramGame
