import React, { ReactElement } from 'react'
import {
  IonContent, IonPage, IonGrid, IonRow, IonCol,
} from '@ionic/react'
import { Game } from '../interfaces/game.interface'
import GameCard from '../components/GameCard'
import Header from '../components/Header'

function HomePage(): ReactElement {
  const games: Game[] = [
    {
      title: 'Anagrammes',
      description: 'Jeu pour trouver des anagrammes.',
      route: '/anagrammes',
      icon: 'anagrams', 
    },
    {
      title: 'Anagrammes',
      description: 'Jeu pour trouver des anagrammes.',
      route: '/anagrammes',
      icon: 'anagrams', 
    },
    {
      title: 'Anagrammes',
      description: 'Jeu pour trouver des anagrammes.',
      route: '/anagrammes',
      icon: 'anagrams', 
    },
    {
      title: 'Anagrammes',
      description: 'Jeu pour trouver des anagrammes.',
      route: '/anagrammes',
      icon: 'anagrams', 
    },
    {
      title: 'Memory',
      description: 'Jeu de mémoire.',
      route: '/memory',
      icon: 'memory', 
    },
  ]

  return (
    <IonPage>
      <Header />
      <IonContent>
        <IonGrid>
          <IonRow
            className="ion-justify-content-center ion-align-items-center"
            style={{ margin: '30px' }}
          >
            {games.map((game, index) => (
              <IonCol
                size="12"
                sizeSm="6"
                sizeMd="4"
                sizeLg="3"
                sizeXl="2.4"
                key={index}
                className="ion-margin-bottom"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <GameCard game={game} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default HomePage
