import React, { ReactElement, useState, useEffect } from 'react'
import { IonGrid, IonRow, IonCol, IonPage, IonTitle, IonButton, IonCard, IonCardContent } from '@ionic/react'
import { createUseStyles } from 'react-jss'
import Header from "../../components/Header"
import GameFrame from '../../components/GameFrame'

const useStyles = createUseStyles({
  title: {
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '28px',
    color: '#333',
  },
  grid: {
    padding: '20px',
    justifyContent: 'center',
  },
  cardContainer: {
    perspective: '1000px',
    margin: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    height: '100px',
    width: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    position: 'relative',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.6s',
    backgroundColor: 'lightblue',
  },
  cardFlipped: {
    transform: 'rotateY(180deg)',
    backgroundColor: '#fff',
  },
  cardMatched: {
    backgroundColor: '#28a745',
    color: '#fff',
    cursor: 'default',
    transform: 'rotateY(180deg)', // Ensure matched cards stay flipped
  },
  button: {
    display: 'block',
    margin: '20px auto',
  },
})

const cardsArray = ['🍎', '🍌', '🍇', '🍉', '🍓', '🍒', '🍑', '🍍', '🍎', '🍌', '🍇', '🍉', '🍓', '🍒', '🍑', '🍍']

function shuffleArray(array: any[]) {
  return array.sort(() => Math.random() - 0.5)
}

function MemoryGame(): ReactElement {
  const classes = useStyles()
  const [cards, setCards] = useState<string[]>([])
  const [flippedCards, setFlippedCards] = useState<number[]>([])
  const [matchedCards, setMatchedCards] = useState<number[]>([])

  useEffect(() => {
    setCards(shuffleArray([...cardsArray]))
  }, [])

  const handleCardClick = (index: number) => {
    if (flippedCards.length === 2 || flippedCards.includes(index) || matchedCards.includes(index)) {
      return
    }

    const newFlippedCards = [...flippedCards, index]
    setFlippedCards(newFlippedCards)

    if (newFlippedCards.length === 2) {
      const [firstIndex, secondIndex] = newFlippedCards
      if (cards[firstIndex] === cards[secondIndex]) {
        setMatchedCards([...matchedCards, firstIndex, secondIndex])
      }
      setTimeout(() => setFlippedCards([]), 1000)
    }
  }

  const resetGame = () => {
    setCards(shuffleArray([...cardsArray]))
    setFlippedCards([])
    setMatchedCards([])
  }

  return (
    <IonPage>
      <Header />
      <GameFrame backgroundUrl={'https://storage.googleapis.com/ortho_pictures/memory.png'}>
        <IonTitle className={classes.title}>Memory Game</IonTitle>
        <IonGrid className={classes.grid}>
          {[0, 1, 2, 3].map(row => (
            <IonRow key={row}>
              {cards.slice(row * 4, row * 4 + 4).map((card, index) => (
                <IonCol size="3" key={index + row * 4}>
                  <IonCard
                    className={`${classes.card} ${flippedCards.includes(index + row * 4) ? 
                      classes.cardFlipped : ''} ${matchedCards.includes(index + row * 4) 
                      ? classes.cardMatched : ''}`}
                    onClick={() => handleCardClick(index + row * 4)}
                  >
                    <IonCardContent>
                      {(flippedCards.includes(index + row * 4)
                          || matchedCards.includes(index + row * 4)) && card}
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              ))}
            </IonRow>
          ))}
        </IonGrid>
        <IonButton className={classes.button} onClick={resetGame}>
            Restart Game
        </IonButton>
      </GameFrame>
    </IonPage>
  )
}

export default MemoryGame
