import React from 'react'
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonImg } from '@ionic/react'
import { Link } from 'react-router-dom'
import { Game } from '../interfaces/game.interface'
import { createUseStyles } from 'react-jss'

interface GameCardProps {
  game: Game;
}

const useStyles = createUseStyles({
  card: {
    position: 'relative',
    width: '250px',
    height: '250px',
    overflow: 'hidden',
    borderRadius: '32px',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'black',
    boxSizing: 'border-box',
    textAlign: 'center',
    pointerEvents: 'none', // To ensure the content doesn't interfere with hover effects
  },
  title: {
    marginTop: -10,
    padding: '8px 16px',
    fontSize: '1.2rem',
    fontWeight: 'normal',
    color: 'black',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.7)',
  },
})

const GameCard: React.FC<GameCardProps> = ({ game }) => {
  const classes = useStyles()

  return (
    <Link to={game.route} style={{ textDecoration: 'none' }}>
      <IonCard className={classes.card}>
        <IonImg
          src={`https://storage.googleapis.com/ortho_pictures/${game.icon}.png`}
          alt={game.title}
          className={classes.img}
        />
        <div className={classes.content}>
          <IonCardHeader style={{ padding: 0 }}>
            <IonCardTitle className={classes.title}>{game.title}</IonCardTitle>
          </IonCardHeader>
        </div>
      </IonCard>
    </Link>
  )
}

export default GameCard
